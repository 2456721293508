










.page-not-found {
  font-size: 1.2rem;
  line-height: 2;

  .v-icon {
    font-size: 4rem;
    opacity: 0.4;
  }
}
