








.facebook-icon {
  fill: currentColor;
  width: 26px;
  height: 26px;
  color: #1877F2;
}

.v-btn--small .facebook-icon {
  width: 22px;
  height: 22px;
}
