




































































.app-img {
  display: flex;
}

.app-img--auto-size .v-image__image {
  background-size: auto;
}
