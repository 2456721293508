








.instagram-icon {
  fill: currentColor;
  width: 24px;
  height: 24px;
}
