@require './_cursors.styl'

body
  font-family: Roboto, Arial, Helvetica, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: #2c3e50
  user-select: none

iframe
  border: none
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 100%
  height: 100%

@media (min-width: 600px)
  ::-webkit-scrollbar
    background-color: #fafafa
    -webkit-overflow-scrolling: touch
    height: 10px
    width: 10px

  ::-webkit-scrollbar:hover
    background-color: #E9E9E9

  ::-webkit-scrollbar-button:start:decrement, ::-webkit-scrollbar-button:end:increment
    background: transparent
    display: block
    height: 0
    width: 0

  ::-webkit-scrollbar-track
    background-clip: padding-box
    border-width: 0 0 0 4px
    border: solid transparent

  ::-webkit-scrollbar-track-piece
    background-color: none
    border-radius: 0

  ::-webkit-scrollbar-thumb
    background-clip: padding-box
    background-color: rgba(0,0,0,0.2)
    border-width: 0
    border: none
    box-shadow: inset 1px 1px 0 rgba(0,0,0,0.1), inset 0 -1px 0 rgba(0,0,0,0.07)

  ::-webkit-scrollbar-thumb:vertical, ::-webkit-scrollbar-thumb:horizontal
    background-color: #ccc
    border-radius: 0

  ::-webkit-scrollbar-thumb:active
    background-color: rgba(0,0,0,0.44)
    box-shadow: inset 1px 1px 3px rgba(0,0,0,0.33)

  ::-webkit-scrollbar-thumb:hover
    background-color: #959595

.dialog-right-transition
  &-enter, &-leave-to
    transform: translateX(100%)

.social-sharing-menu.v-menu__content .v-list__tile__action
  min-width: 40px

@media print
  *
    visibility: hidden !important

  .printing, .printing *
    visibility: visible !important

  .v-navigation-drawer--right
    right: unset !important

/* vuetify overrides */
.theme--light.v-text-field--box > .v-input__control > .v-input__slot
  border-radius: 0
  background: rgba(0,0,0,0.04)

  &:before
    display: none

.v-text-field .v-input__prepend-inner
  padding-right: 8px

.v-dialog--fullscreen > .v-card
  border-radius: 0

.v-menu__content
  .v-divider:last-child
    display: none

.application.iframe .v-toolbar--fixed
  transition: none;

.overflow-y-hidden
  .application.iframe
    padding-right: 10px // compensate for missing scrollbar

    .v-toolbar--fixed
      width: calc(100% - 10px)

/* vuetify fixes */
.v-text-field__details
  overflow: visible !important

.v-messages__message
  line-height: 1 !important

.v-list__group:first-child:before
  display: none;

.v-list__group:last-child:after
  display: none;

