










.mailchimp-icon {
  fill: currentColor;
  width: 26px;
  height: 26px;
  color: black
}

.v-btn--small .mailchimp-icon {
  width: 20px;
  height: 20px;
}
