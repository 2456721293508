








.twitter-icon {
  fill: currentColor;
  width: 24px;
  height: 24px;
  color: #1DA1F2;
}

.v-btn--small .twitter-icon {
  width: 22px;
  height: 22px;
}
