








.pageview-icon {
  fill: currentColor;
  width: 22px;
  height: 22px;
}
