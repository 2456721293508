
































.frequent-shopper-icon {
  height: 40px;
}

.frequent-shopper-icon--dark.theme--dark {
  filter: brightness(0) saturate(0) invert(1);
}
